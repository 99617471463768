import React, { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import { appWithTranslation } from "next-i18next";
import AOS from "aos";
import { init } from "@socialgouv/matomo-next";

import theme from "../lib/theme";
import createEmotionCache from "../lib/createEmotionCache";
import Layout from "../components/Common/Layout";

import "aos/dist/aos.css";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  const { locale, pathname } = useRouter();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      init({
        url: "https://fluicity.matomo.cloud",
        siteId: "3",
      });
    }
  }, []);

  useEffect(() => {
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      {locale === "fr" && (
        <>
          <Script
            src="//leadbooster-chat.pipedrive.com/assets/loader.js"
            async
          />
          <Script
            id="chatbot"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.pipedriveLeadboosterConfig = {
              base: "leadbooster-chat.pipedrive.com",
              companyId: 968947,
              playbookUuid: "d9db210f-265d-41f5-94f0-887534aca6b0",
              version: 2,
            };
            if (window.LeadBooster) {
              console.warn("LeadBooster already exists");
            } else {
              window.LeadBooster = {
                q: [],
                on: function (n, h) {
                  this.q.push({ t: "o", n: n, h: h });
                },
                trigger: function (n) {
                  this.q.push({ t: "t", n: n });
                },
              };
            }
            `,
            }}
          />
        </>
      )}
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {pathname.startsWith("/admin") ? (
          <Component {...pageProps} /> // don't wrap netlify cms pages in Layout
        ) : (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        )}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default appWithTranslation(MyApp);
