import React from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

const Navbar = dynamic(() => import("../Navbar"));
const Footer = dynamic(() => import("../Footer"));

import Container from "../Container";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />

      <Box component="main">
        {children}
        <Divider />
      </Box>

      <Container paddingY={4}>
        <Footer />
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
