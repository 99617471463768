import { responsiveFontSizes } from "@mui/material";
import { alpha, createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      alternate: {
        main: "#f7f9fc",
        dark: "#e9eafd",
      },
      primary: {
        main: "#5c5afa",
        contrastText: "#ffffff",
        inverted: "white",
      },
      secondary: {
        main: "#38b9be",
        contrastText: "#ffffff",
      },
      tertiary: {
        main: "#fdb13f",
        contrastText: "#ffffff",
      },
      quaternary: {
        main: "#fc554b",
        contrastText: "#ffffff",
      },
      success: {
        main: "#00d291",
        contrastText: "#ffffff",
      },
      info: {
        main: "#5c5afa",
        contrastText: "#ffffff",
      },
      neutral: {
        main: "#9D9D9D",
        contrastText: "#ffffff",
      },
      text: {
        primary: "#2d3748",
        secondary: "#646e73",
      },
    },
    layout: {
      contentWidth: 1236,
    },
    shadows: [
      "none",
      `0 3px 6px 0 ${alpha("#8c98a4", 0.25)}`,
      `0 12px 15px ${alpha("#8c98a4", 0.1)}`,
      `0 6px 24px 0 ${alpha("#8c98a4", 0.125)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
      `0 10px 40px 10px ${alpha("#8c98a4", 0.175)}`,
    ],
    typography: {
      fontFamily: '"Inter", sans-serif',
      button: {
        textTransform: "none",
        fontWeight: "medium",
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1300,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          label: {
            fontWeight: 600,
          },
          containedSecondary: {
            color: "white",
          },
        },
      },
    },
  })
);

export default theme;
